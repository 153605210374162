import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DetailsModelInterface, FilterData } from "interfaces/details";
import { RootState } from "app/store";
import { getConfiguration } from "app/utils";
import configuration from "components/features/Details/configuration";

interface DetailsState {
  model: DetailsModelInterface;
}

const initialState: DetailsState = {
  model: getConfiguration<DetailsState>(configuration).model,
};

export const detailsSlice = createSlice({
  name: "details",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDetailsModel: (
      state,
      action: PayloadAction<Partial<DetailsModelInterface>>,
    ) => {
      state.model = {
        ...state.model,
        ...action.payload,
      };
    },
    setFilterData: (
      state,
      action: PayloadAction<{ [key: string]: FilterData }>,
    ) => {
      const keys = action.payload as any;
      Object.keys(keys).forEach((key) => {
        if (!(key in state.model.filterData)) {
          console.error(
            `Warning: ${key} is not defined in FilterData interface`,
          );
        }
      });
      state.model.filterData = {
        ...state.model.filterData,
        ...action.payload,
      };
    },
    resetDetails: (state) => {
      state.model = initialState.model;
    },
    resetFareSelectionData: (state) => {
      state.model.travelClass = "";
      state.model.totalPrice = {
        amount: 0,
        currency: "EUR",
      };
    },
  },
});

export const {
  setDetailsModel,
  resetDetails,
  resetFareSelectionData,
  setFilterData,
} = detailsSlice.actions;

export const selectDetailsModel = (state: RootState) => state.details.model;

export default detailsSlice.reducer;
